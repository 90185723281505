<template lang="pug">
.background-wrapper
	.container.section
		h3.intro-title {{ blok.title }}
		.columns.is-multiline.products
			.column.is-full
				.product.plan-freemium.product-row
					.row-left.no-shrink
						span.product-title Singa Free
						span.price-wrap(v-if="planMonthly")
							span.price {{ formattedCurrency(0, planMonthly.currency) }}
							span &nbsp;/ {{ blok.plan_yearly }}
					.row-right
						span.free-desc {{ blok.freemium_desc }}
						SingaButton.is-outlined-black.is-rounded(tag="router-link" :to="localePath('/login')") {{ blok.freemium_cta }}

			.column.is-full-mobile.is-one-third
				a.product.plan-monthly(href="https://singa.com/choose-plan/payment?product_id=trial&product_type=trial")
					span.product-title Singa Premium
					.price-wrap(v-if="planYearly")
						span.price {{ formattedCurrency(planMonthly.amount, planMonthly.currency) }}
						span &nbsp;/ {{ blok.plan_monthly }}
					span.product-desc {{ blok.plan_desc}}
					SingaButton.is-primary.is-rounded(tag="div") {{ blok.plan_cta }}

			.column.is-full-mobile.is-one-third
				a.product.plan-yearly(href="https://singa.com/choose-plan/")
					span.product-title Singa Premium
					.price-wrap(v-if="planYearly")
						span.price {{ formattedCurrency(planYearly.amount, planYearly.currency) }}
						span.yearly &nbsp;/ {{ blok.plan_yearly }}
						span.monthly {{ planYearly.monthly }} / {{ blok.plan_monthly }}
					span.product-desc {{ blok.plan_desc }}
					SingaButton.is-primary.is-rounded(tag="div") {{ blok.plan_cta }}

			.column.is-full-mobile.is-one-third
				a.product.pass(href="https://singa.com/choose-plan/payment?product_id=pass&product_type=regular")
					span.product-title {{ blok.pass_title }}
					.price-wrap(v-if="pass")
						span.price {{ formattedCurrency(pass.amount, pass.currency) }}
					span.product-desc {{ blok.pass_desc }}
					SingaButton.is-outlined-black.is-rounded(tag="div") {{ blok.pass_cta }}

			.column.is-full
				.business.product-row.product
					span.product-title {{ blok.business_title }}
					SingaButton.is-outlined-black.is-rounded(tag="a" href="https://singa.com/business/") {{ blok.business_cta }}
</template>

<script setup lang="ts">
const { $singaApi } = useNuxtApp()
const localePath = useLocalePath()
defineProps({
	blok: {
		required: true,
		type: Object
	}
})

const planMonthly = ref()
const planYearly = ref()
const pass = ref()

const currencySymbols = {
	eur: '€',
	sek: 'kr',
	gbp: '£',
	rub: 'RUB',
	aud: '$',
	usd: '$',
	cad: '$',
	nok: 'kr',
	isk: 'kr',
	dkk: 'kr',
	ron: 'lei',
	pln: 'zł',
	chf: 'fr.',
	huf: 'Ft',
	czk: 'Kč',
	bgn: 'лв',
	php: '₱',
	mxn: '$',
	uah: '₴'
}

const beforeCurrencies = ['usd', 'cad', 'aud', 'gbp', 'nok', 'mxn', 'uah']
type CurrencyCode = keyof typeof currencySymbols
const formattedCurrency = (amount: number, currency: CurrencyCode) => {
	const symbol = currencySymbols[currency] as any || currency.toUpperCase()
	return beforeCurrencies.includes(currency) ? `${symbol}${(amount / 100).toFixed(2)}` : `${(amount / 100).toFixed(2)} ${symbol}`
}

const { data: plans } = await $singaApi.Products.getPlans()

const { data: passes } = await $singaApi.Products.getPasses()
onMounted(() => {
	if (import.meta.client) {
		try {
			if (plans.value) {
				planMonthly.value = plans.value.results.find((plan: any) => plan.interval === 'month')
				planYearly.value = plans.value.results.find((plan: any) => plan.interval === 'year')
				if (planYearly.value) {
					planYearly.value.monthly = formattedCurrency(planYearly.value.amount / 12, planYearly.value.currency)
				}
			}
			if (passes) {
				pass.value = passes.value.results[0]
			}
		} catch (err) {
			console.log(err)
		}
	}
})
</script>

<style lang="sass" scoped>
.background-wrapper
	background-color: #fff
	padding-bottom: $spacing-64

.intro-title
	text-align: center
	color: black
	@include font(basier, bold)
	@include fontSize(xxl)
	max-width: 1000px
	text-align: center
	padding-top: $spacing-40
	padding-bottom: $spacing-40
	@media (min-width: $tablet)
		padding-top: $spacing-64
		padding-bottom: $spacing-64
		@include fontSize(5xl)
.products
	span
		color: black
	.product-title
		@include fontSize(l)
	.button
		min-height: 45px
.price-wrap
	.price
		@include font(basier, bold)
		@include fontSize(xxl)
.product
	display: flex
	flex-direction: column
	border-radius: $spacing-8
	border: 1px solid #e0e0e0
	position: relative
	z-index: 1
	padding: $spacing-24
	transition: all 200ms
	cursor: pointer
	max-width: 327px
	margin: 0 auto
	@media (min-width: $tablet)
		max-width: 100%
	&:after
		position: absolute
		content: ''
		top: 0
		right: 0
		bottom: 0
		left: 0
		box-shadow: 0 2px 9px 2px rgba(0,0,0,.2)
		transition: opacity ease .2s
		z-index: -1
		border-radius: $spacing-8
		opacity: .2
	&:hover
		&:not(.product-row)
			transform: translateY(-4px)
		&:after
			opacity: 1
	.product-desc, .free-desc
		padding: $spacing-16 0
		@media (min-width: $tablet)
			padding: 0
	.price-wrap
		padding: $spacing-8 0 0
		@media (min-width: $tablet)
			padding: 0
	.product-desc
		@media (min-width: $tablet)
			min-height: 104px
.product-row
	display: flex
	justify-content: space-between
	flex-direction: column
	padding: $spacing-16 $spacing-24
	.no-shrink
		flex-shrink: 0
	@media (min-width: $tablet)
		align-items: center
		flex-direction: row
		flex-wrap: wrap
	.row-left, .row-right
		display: flex
		flex-direction: column
		align-items: flex-start
		.button
			width: 100%
		@media (min-width: $tablet)
			flex-direction: row
			gap: $spacing-16
			align-items: center
.business
	@media (max-width: $tablet)
		.button
			margin-top: $spacing-16
.is-primary
	background-color: #17E87A
.monthly
	display: inline-block
	background: #d4ffe7
	border-radius: 4px
	padding: 0 6px
	line-height: 12px
	@include fontSize(xxs)
	@include font(basier, semiBold)
	color: #12b961 !important
	letter-spacing: .014285714285714rem
.yearly
	margin-right: $spacing-8
</style>
